const linen = {
  50: '#FBFAF9',
  100: '#F6F5F3',
  200: '#F0EEEB',
  300: '#E8E0D8',
  400: '#D5C7B8',
};

const summerRed = {
  300: '#E68187',
  400: '#DD5960',
  500: '#D5313A',
  600: '#AD1921',
  700: '#7D0910',
};

const dustyPink = {
  50: '#FFF9F7',
  100: '#FFE7DF',
  200: '#FFD5C8',
  300: '#FFC3B0',
  400: '#E9AA96',
};

const importance = {
  200: '#FFEFD3',
  300: '#FED193',
  400: '#F4A445',
  500: '#E0963E',
  600: '#B6792F',
};

const success = {
  200: '#C1E9CA',
  300: '#93D9A2',
  400: '#64C97A',
  500: '#4B975C',
  600: '#32653D',
};

const error = {
  200: '#FBBBB7',
  300: '#F78981',
  400: '#F4564B',
  500: '#B74138',
  600: '#7A2B26',
};

const gray = {
  50: '#F2F2F2',
  100: '#E6E6E6',
  200: '#D9D9D9',
  300: '#BABABA',
  400: '#9B9B9B',
  500: '#8C8C8C',
  600: '#757575',
  700: '#595959',
  800: '#404040',
  900: '#262626',
};

const royalBlue = '#1A417B';

const gold = '#D6B161';

const colors = {
  linen: {
    DEFAULT: linen[200],
    ...linen,
  },
  'summer-red': {
    DEFAULT: summerRed[500],
    ...summerRed,
  },
  'dusty-pink': {
    DEFAULT: dustyPink[200],
    ...dustyPink,
  },
  importance: {
    DEFAULT: importance[400],
    ...importance,
  },
  success: {
    DEFAULT: success[400],
    ...success,
  },
  error: {
    DEFAULT: error[400],
    ...error,
  },
  gray: {
    DEFAULT: gray[600],
    ...gray,
  },
  gold: {
    DEFAULT: gold,
  },
  'royal-blue': {
    DEFAULT: royalBlue,
  },
};

module.exports = colors;
