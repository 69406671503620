import { BrandOcelotConfig } from '@/types/ocelotConfig';

const FeminaConfig: BrandOcelotConfig = {
  search: {
    googleCustomSearch: {
      id: '008262495618786222540:0lrfa0hhm5a',
    },
    placeholderImage: '/image/femina-logo.svg',
  },
  analytics: {
    brandName: 'femina',
    google: {
      dimensions: {
        page_id: '1',
        mobile_website: '2',
        categories: '7',
      },
      viewId: 143384156,
      hostname: 'www.femina.se',
    },
    parsely: {
      siteId: 'femina.se',
    },
    tealium: {
      account: 'aller-media-sweden',
      profile: 'main',
      environment: 'prod',
    },
    funnelRelay: {
      customerId: 'aller_e7bce_femina',
      scriptSource: 'cdn-magiclinks.trackonomics.net/client/static/v2/aller_e7bce_femina.js',
    },
    avo: {
      apiKey: 'blcuGqEsX2nT6VJtqDMv',
    },
    tags: ['funnelRelay', 'engagedTime', 'scrollDepthTracker', 'loggedInId', 'metadata', 'webVitals'],
    jwplayer: true,
  },
  features: {
    strossle: {
      enabled: true,
      id: 'e5fc2345-6a06-4fb6-b995-eaf69cc9cacb',
    },
    login: {
      enabled: true,
      brandName: 'femina__se',
    },
    infinityScrollArticle: {
      enabled: true,
    },
    saveArticle: {
      enabled: true,
    },
    trendingMenu: {
      label: 'Trendar: ',
    },
    payments: {
      enabled: true,
      prices: {
        regular: '29',
      },
      purchaseTag: 'subscription',
    },
  },

  pages: {
    instagram: {
      enabled: false,
    },
  },
  seo: {
    schemaMarkup: {
      name: 'Femina',
      foundingDate: '1944-01-01',
      alternateName: ['Femina.se'],
      sameAs: [
        'https://sv.wikipedia.org/wiki/Femina',
        'https://www.wikidata.org/wiki/Q10494600',
        'https://www.pinterest.se/feminasverige/',
        'https://www.facebook.com/femina.se',
        'https://x.com/femina_se',
        'https://www.instagram.com/feminasverige/',
      ],
      webPages: ['/kontakt', '/om-oss', '/nyhetsbrev', '/om-cookies', '/alla-amnen', '/vara-skribenter'],
    },
  },
  ads: {
    video: {
      clickToPlay: 'VYCGmwt4',
      autoPlay: 'uBH5g2Sr',
      videoPage: 'y6mToUfQ',
    },
    preload: {
      enabled: true,
    },
  },
  cmp: {
    onetrust_id: '8cca5d24-ebdc-4f8c-a1ec-3c13e78eee4d',
    preload: {
      enabled: true,
    },
  },
};

export default FeminaConfig;
