const headline = {
  '3xl': {
    font: 'primary',
    size: '61px',
    weight: '400',
    leading: '1.2',
  },
  '2xl': {
    font: 'primary',
    size: '48px',
    weight: '400',
    leading: '1.04',
  },
  xl: {
    font: 'primary',
    size: '39px',
    weight: '400',
    leading: '1.08',
  },
  lg: {
    font: 'primary',
    size: '32px',
    weight: '400',
    leading: '1.13',
  },
  md: {
    font: 'primary',
    size: '25px',
    weight: '400',
    leading: '1.2',
  },
  sm: {
    font: 'primary',
    size: '20px',
    weight: '400',
    leading: '1.2',
  },
  xs: {
    font: 'primary',
    size: '16px',
    weight: '400',
    leading: '1.25',
  },
};

const preamble = {
  lg: {
    font: 'secondary',
    size: '20px',
    weight: '400',
    leading: '1.3',
  },
  sm: {
    font: 'secondary',
    size: '16px',
    weight: '400',
    leading: '1.5',
  },
  xs: {
    font: 'secondary',
    size: '12px',
    weight: '400',
    leading: '1.5',
  },
};

const body = {
  lg: {
    font: 'tertiary',
    size: '20px',
    weight: '400',
    leading: '1.5',
  },
  sm: {
    font: 'tertiary',
    size: '16px',
    weight: '400',
    leading: '1.5',
  },
};

const category = {
  lg: {
    font: 'secondary',
    size: '16px',
    weight: '400',
    leading: '1.25',
    transform: 'uppercase',
    color: 'summer-red[600]',
  },
  sm: {
    font: 'secondary',
    size: '12px',
    weight: '400',
    leading: '1.34',
    transform: 'uppercase',
    color: 'summer-red[600]',
  },
};

const content = {
  lg: {
    font: 'secondary',
    size: '25px',
    weight: '400',
    leading: '1.12',
  },
  md: {
    font: 'secondary',
    size: '20px',
    weight: '400',
    leading: '1.2',
  },
  sm: {
    font: 'secondary',
    size: '16px',
    weight: '400',
    leading: '1.25',
  },
  xs: {
    font: 'secondary',
    size: '12px',
    weight: '400',
    leading: '1.34',
  },
};

const contentHeavy = Object.fromEntries(
  Object.entries(content).map(([key, value]) => [key, { ...value, weight: 900 }]),
);

const typography = {
  headline: {
    DEFAULT: headline.md,
    ...headline,
  },
  preamble: {
    DEFAULT: preamble.sm,
    ...preamble,
  },
  body: {
    DEFAULT: body.sm,
    ...body,
  },
  category: {
    DEFAULT: category.sm,
    ...category,
  },
  content: {
    DEFAULT: content.md,
    ...content,
  },
  'content-heavy': {
    DEFAULT: contentHeavy.md,
    ...contentHeavy,
  },
};

module.exports = typography;
