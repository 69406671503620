const typographyPlugin = require('tailwind-utils/typography.plugin');

module.exports = (typographyConfig) => (theme) => {
  const typograph = (name) => typographyPlugin.getUtilities(theme, typographyConfig)[`.text-${name}`];

  return {
    DEFAULT: {
      css: {
        '--tw-prose-body': theme('colors.black'),
        '--tw-prose-headings': theme('colors.black'),
        '--tw-prose-links': theme('colors.summer-red.600'),
        '--tw-prose-bullets': theme('colors.black'),
        '--tw-prose-counters': theme('colors.black'),

        'max-width': '100%',

        ...typograph('body-lg'),

        h1: {
          ...typograph('content-heavy-md'),
          marginTop: theme('spacing[6]'),
          marginBottom: theme('spacing[2]'),

          '@media screen(sm)': {
            ...typograph('content-heavy-lg'),
            marginTop: theme('spacing[9]'),
            marginBottom: theme('spacing[3]'),
          },
        },

        h2: {
          ...typograph('content-heavy-md'),
          marginTop: theme('spacing[6]'),
          marginBottom: theme('spacing[2]'),

          '@media screen(sm)': {
            ...typograph('content-heavy-lg'),
            marginTop: theme('spacing[9]'),
            marginBottom: theme('spacing[3]'),
          },
        },

        h3: {
          ...typograph('content-heavy-sm'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[2]'),

          '@media screen(sm)': {
            ...typograph('content-heavy-md'),
            marginTop: theme('spacing[4]'),
            marginBottom: theme('spacing[3]'),
          },
        },

        h4: {
          ...typograph('content-heavy-xs'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[1.5]'),

          '@media screen(sm)': typograph('content-heavy-sm'),
        },

        h5: {
          ...typograph('content-heavy-xs'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[1.5]'),
        },

        h6: {
          ...typograph('content-heavy-xs'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[1.5]'),
        },

        a: {
          '&:hover': {
            color: theme('colors.summer-red.600'),
            textDecoration: 'underline',
          },

          '&:visited': {
            color: theme('colors.summer-red.700'),
          },
        },

        p: {
          ...typograph('body-sm'),
          marginTop: theme('spacing[6]'),
          marginBottom: theme('spacing[6]'),

          '@media screen(sm)': typograph('body-lg'),
        },

        ol: {
          marginBottom: theme('spacing[3]'),
        },

        ul: {
          marginBottom: theme('spacing[3]'),
        },

        li: {
          ...typograph('body-sm'),
          marginBottom: '0',
          paddingLeft: '0',

          p: {
            display: 'inline',
          },

          '@media screen(sm)': typograph('body-lg'),
        },

        figure: {
          marginTop: theme('spacing[6]'),
          marginBottom: theme('spacing[6]'),
        },
      },
    },

    coverbox: {
      css: {
        p: {
          ...typograph('preamble-xs'),
          marginBottom: theme('spacing[0.5]'),

          '@media screen(sm)': typograph('preamble-sm'),
        },

        a: {
          textDecoration: 'none',
        },
      },
    },

    factbox: {
      css: {
        '--tw-prose-body': 'initial',
        h2: {
          ...typograph('content-lg'),
          marginTop: theme('spacing[4]'),
          marginBottom: '0',
        },

        h3: {
          ...typograph('content-md'),
          marginTop: theme('spacing[4]'),
          marginBottom: '0',
        },

        p: {
          ...typograph('preamble-sm'),
          marginTop: theme('spacing[2]'),
          marginBottom: '0',
        },

        a: {
          textDecoration: 'none',
        },

        ul: {
          paddingLeft: theme('spacing[6]'),
          marginTop: theme('spacing[3]'),
          marginBottom: '0',
        },

        ol: {
          paddingLeft: theme('spacing[6]'),
          marginTop: theme('spacing[3]'),
          marginBottom: '0',
        },

        li: {
          ...typograph('preamble-sm'),
          marginTop: 0,

          '&::marker': {
            color: 'inherit',
          },
        },
      },
    },
  };
};
