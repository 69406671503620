import { tw } from '@/utils/tw';
import BaseNavigationTheme from 'base/components/Navigation/theme';

const Button = tw.theme({
  extend: BaseNavigationTheme.Button,
  variants: {
    size: {
      small: ['!px-1.5', 'sm:!px-3', 'md:!px-5'],
    },
  },
});

const BaseLinkVariants = BaseNavigationTheme.Link.variants;

const Link = tw.theme({
  variants: {
    colors: {
      bar: ['text-black', 'hover:text-summer-red-600'],
      drawer: ['text-black', 'hover:text-summer-red-600'],
    },
    size: {
      ...BaseLinkVariants.size,
      bar: [BaseLinkVariants.size.bar, 'text-content-sm', 'px-5', 'py-2.5', 'xl:px-3.5'],
      drawer: [BaseLinkVariants.size.drawer, 'text-content-sm', "[&:not([data-depth='1'])]:ml-4"],
    },
    variant: {
      ...BaseLinkVariants.variant,
      bar: [
        'block',
        'transition-colors',
        'xl:uppercase',
        'sm:aria-[current=page]:bg-inherit',
        'aria-[current=page]:bg-linen-200',
      ],
      drawerPrimary: ['block', "data-[depth='1']:!font-bold"],
    },
  },
});

export default Object.assign({}, { Button, Link });
